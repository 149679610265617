import ApplicationLogo from '@/Components/ApplicationLogo';
import { useFlashNotifications } from '@/Hooks/useFlashNotifications';
import BackgroundImage from '@image/login-background.webp';
import { Link } from '@inertiajs/react';
import { PropsWithChildren } from 'react';

export default function GuestLayout({ children }: PropsWithChildren) {
  useFlashNotifications();
  return (
    <div className="flex min-h-screen bg-gray-100">
      <div className="z-10 flex w-full flex-col items-center justify-center bg-primary text-white lg:w-[50vw]">
        <div className="flex w-full flex-col gap-12 px-10 md:max-w-lg">
          <Link href="/" className="inline-block">
            <ApplicationLogo className="h-20 w-min rounded-3xl bg-white px-5 py-2 shadow-lg" />
          </Link>
          {children}
        </div>
      </div>
      <img
        src={BackgroundImage}
        alt="Background Image"
        className="-ml-[180px] hidden h-screen w-[calc(50vw+180px)] object-cover object-left-top lg:block"
      />
    </div>
  );
}
